import '../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/brands.min.css';
import 'swiper/swiper-bundle.min.css';
import '../scss/app-foundation.scss';
import '../scss/app.scss';
import 'aos/dist/aos.css';

import $ from 'jquery/dist/jquery';
import {Swiper} from "swiper";
import {Tabs} from 'foundation-sites/js/foundation';
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import AOS from 'aos';

(function ($) {

    dayjs.locale('ru');

    $(document).foundation();

    $(window).on('load', () => {
        AOS.init({
            // offset: 50
        });
    });

    $.ajax({
        url: 'https://ls-proxy.sochihockeyopen.ru/api.php',
        dataType: "json",
        type: "get",
        async: true,
        data: {},
        success: onAjaxSuccess,
        error: function (xhr, exception) {
            console.log(xhr, exception);
        }
    });

    function onAjaxSuccess(data) {
        const $parent = $('#matches-grid');
        const template = document.getElementById('matches-grid-item-template');

        data.forEach(element => {
            let $item = $(template.content.cloneNode(true));

            // date & time
            $item.find('[data-slot=date]').text(dayjs(element.timestamp).format('D MMMM'));
            $item.find('[data-slot=time]').text(dayjs(element.timestamp).format('HH:mm'));

            // team1
            $item.find('[data-slot=team1-logo]').attr('src', element.team1LogoUrl);
            $item.find('[data-slot=team1-title]').text(element.team1);

            // team2
            $item.find('[data-slot=team2-logo]').attr('src', element.team2LogoUrl);
            $item.find('[data-slot=team2-title]').text(element.team2);

            // coefs
            $item.find('[data-slot=coef-1]').text(element.outcomes._1.value);
            $item.find('[data-slot=coef-x]').text(element.outcomes.x.value);
            $item.find('[data-slot=coef-2]').text(element.outcomes._2.value);

            // link
            $item.find('[data-slot=match-link]').attr('href', element.link)

            $parent.append($item);
        });
    }

    // teams slider
    const teamsSwiper = new Swiper('#teams-slider', {
        loop: true,
        slidesPerView: "auto",
        freeMode: false,
        centeredSlides: true,
        centerInsufficientSlides: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
        }
    });

})($);
